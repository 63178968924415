import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'

const Pagination = ({ currentPage, totalPages, setPage, slug }) => {
  const isNotAbleToPrev = currentPage === 0
  const isNotAbleToNext = currentPage === totalPages - 1

  return (
    <S.PaginationList>
      <S.PaginationListItem>
        <S.PaginationIconLeft 
            disabled={isNotAbleToPrev}
            onClick={() => {
                !isNotAbleToPrev && (
                    setPage(currentPage - 1)
                )
            }} 
        />
      </S.PaginationListItem>
      <S.PaginationListItem>
        {((currentPage + 1) < totalPages && currentPage !== 0) && (
          <S.PaginationItem
            onClick={() => {
              setPage(currentPage - 1)
            }}
          >
            {currentPage}
          </S.PaginationItem>
        )}

        {(currentPage + 1) === totalPages && currentPage !== 0 && (
          <S.PaginationItem
            onClick={() => {
              setPage(currentPage - 1)
            }}
          >
              {currentPage}
          </S.PaginationItem>
        )}

        <S.PaginationItemActive
          onClick={() => {
            setPage(currentPage)
          }}
        >
            {currentPage + 1}
        </S.PaginationItemActive>

        {(currentPage + 2) < totalPages && (
          <S.PaginationItem
            onClick={() => {
              setPage(currentPage + 1)
            }}
          >
            {currentPage + 2}
          </S.PaginationItem>    
        )}

        {((currentPage + 2) < (totalPages - 1)) && (
          <S.PaginationItem disabled>
                ...
          </S.PaginationItem>
        )}

        {(currentPage + 1) < totalPages && (
          <S.PaginationItem
            onClick={() => {
              setPage(totalPages - 1)
            }}
          >
              {totalPages}
          </S.PaginationItem>
        )}
      </S.PaginationListItem>
      <S.PaginationListItem>
          <S.PaginationIconRight 
            disabled={isNotAbleToNext}
            onClick={() => {
                !isNotAbleToNext && (
                    setPage(currentPage + 1)
                )
            }} />
      </S.PaginationListItem>
    </S.PaginationList>
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
}

export default Pagination

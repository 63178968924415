import React from 'react'
import * as S from './styled'
import PinkLine from '../shared/PinkLine'
import ImgMedical from './ImgMedical'
import Img from 'gatsby-image'
import { ContainerDasa } from '../shared/container'
import { Button } from "@dasa-health/alma-react"

const ClinicalStaffNoPosts = (props) => {
    const doctor = props.doctor
    const imageBackground = props.data.imagem_interna_corpo_clinico
    
    return (
        <>
            <S.ContainerSingleClinicalStaff>
              <ContainerDasa>
                <S.ClinicalStaffContainer>
                  <S.Image fluid={doctor.photo_medic.localFile.childImageSharp.fluid} alt={doctor.name} />
                  
                  <S.SpecialtyArea>{doctor.specialization_area}</S.SpecialtyArea>
                  
                  <S.Name>
                    {doctor.title_gender} {doctor.name}
                  </S.Name>

                  <S.Council>
                    <S.CouncilNumber>{doctor.council_number}</S.CouncilNumber>
                  </S.Council>
              
                  <S.ClinicalStaffDescription>
                    <div dangerouslySetInnerHTML={{ __html: doctor.description }} />
                  </S.ClinicalStaffDescription>

                  {doctor.title_button && doctor.link_button && 
                    <S.Button>
                      <S.ButtonLink href={doctor.link_button}>
                        <Button
                          size="lg"
                          variant="secondary"
                        >
                          {doctor.title_button}
                        </Button>
                      </S.ButtonLink>
                    </S.Button>
                  }
                </S.ClinicalStaffContainer>
              </ContainerDasa>

              <S.ImgMedicalNoPub>
                {imageBackground
                  ?
                    <Img 
                      fluid={imageBackground.localFile.childImageSharp.fluid}
                      alt={imageBackground.alt_text}
                    />
                  :
                    <ImgMedical />
                }
              </S.ImgMedicalNoPub>
            </S.ContainerSingleClinicalStaff>
            <PinkLine />
        </>
    )
}

export default ClinicalStaffNoPosts

import React from 'react'

const ImgMedical = () => {
    return (
        <svg width="332" height="462" viewBox="0 0 332 462" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_720_15137" style={{ 'mask-type': 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="332" height="462">
                <rect width="332" height="462" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_720_15137)">
                <path d="M200.541 164.817C165.03 164.817 138.372 141.061 138.372 104.369C138.372 68.2186 165.03 44.4626 200.541 44.4626C236.593 44.4626 263.251 68.2186 263.251 104.369C263.251 141.061 236.593 164.817 200.541 164.817ZM200.541 209.279C262.857 209.279 309.287 168.358 309.287 104.369C309.287 40.9213 262.857 0 200.541 0C138.765 0 92.3355 40.9213 92.3355 104.369C92.2863 168.358 138.765 209.279 200.541 209.279ZM-2 461.644H44.0365V414.033C44.0365 317.583 90.9091 266.579 200.541 266.579C310.861 266.579 358.964 318.419 358.964 414.525V461.693H405V415.558C405 281.334 318.927 222.116 200.541 222.116C82.9904 222.116 -2 276.121 -2 415.115V461.644Z" fill="white"/>
                <path d="M206.492 425.645H236.937C234.084 417.775 233.297 408.528 233.297 403.462C233.297 383.74 241.659 373.116 260.398 373.116C279.137 373.116 287.498 383.69 287.498 403.462C287.498 408.528 286.761 417.775 283.859 425.645H314.304C318.239 415.267 318.976 403.856 318.976 395.691C318.976 365.541 302.155 347.343 275.104 342.72V231.317C265.71 228.76 255.873 226.743 245.692 225.317V342.671C218.64 347.294 201.819 365.541 201.819 395.642C201.819 403.856 202.557 415.267 206.492 425.645Z" fill="white"/>
                <path d="M127.403 423.671C149.634 423.671 162.963 409.162 162.963 387.964C162.963 371.438 154.75 359.732 141.076 355.108V227.524C131.584 229.295 122.485 231.558 113.73 234.361V355.059C99.368 359.043 90.5641 371.241 90.5641 387.914C90.5641 409.162 104.532 423.671 127.403 423.671Z" fill="white"/>
                <path d="M200.541 164.817C165.03 164.817 138.372 141.061 138.372 104.369C138.372 68.2186 165.03 44.4626 200.541 44.4626C236.593 44.4626 263.251 68.2186 263.251 104.369C263.251 141.061 236.593 164.817 200.541 164.817ZM200.541 209.279C262.857 209.279 309.287 168.358 309.287 104.369C309.287 40.9213 262.857 0 200.541 0C138.765 0 92.3355 40.9213 92.3355 104.369C92.2863 168.358 138.765 209.279 200.541 209.279ZM-2 461.644H44.0365V414.033C44.0365 317.583 90.9091 266.579 200.541 266.579C310.861 266.579 358.964 318.419 358.964 414.525V461.693H405V415.558C405 281.334 318.927 222.116 200.541 222.116C82.9904 222.116 -2 276.121 -2 415.115V461.644Z" fill="#D22673"/>
                <path d="M206.492 425.645H236.937C234.084 417.775 233.297 408.528 233.297 403.462C233.297 383.74 241.659 373.116 260.398 373.116C279.137 373.116 287.498 383.69 287.498 403.462C287.498 408.528 286.761 417.775 283.859 425.645H314.304C318.239 415.267 318.976 403.856 318.976 395.691C318.976 365.541 302.155 347.343 275.104 342.72V231.317C265.71 228.76 255.873 226.743 245.692 225.317V342.671C218.64 347.294 201.819 365.541 201.819 395.642C201.819 403.856 202.557 415.267 206.492 425.645Z" fill="#A7E1FF"/>
                <path d="M127.403 423.671C149.634 423.671 162.963 409.162 162.963 387.964C162.963 371.438 154.75 359.732 141.076 355.108V227.524C131.584 229.295 122.485 231.558 113.73 234.361V355.059C99.368 359.043 90.5641 371.241 90.5641 387.914C90.5641 409.162 104.532 423.671 127.403 423.671Z" fill="#A7E1FF"/>
            </g>
        </svg>
    )
}

export default ImgMedical

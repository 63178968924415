import React from 'react'
import ClinicalStaffNoPosts from './ClinicalStaffNoPosts'
import ClinicalStaffWithPosts from './ClinicalStaffWithPosts'

const SingleClinicalStaff = ({ doctor, posts, data }) => {
  const medicPosts = posts.edges && posts.edges.length > 0 ? true : false 

  return (
    !medicPosts
    ?
      <ClinicalStaffNoPosts 
        doctor={doctor} 
        data={data}
      />
    :
      <ClinicalStaffWithPosts
        doctor={doctor}
        posts={posts}
        data={data}
      />
   )
}

export default SingleClinicalStaff

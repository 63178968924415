import React, { useState, useEffect } from 'react'
import * as S from './styled'
import { ContainerDasa } from '../shared/container'
import PinkLine from '../shared/PinkLine'
import ImgMedical from './ImgMedical'
import { Button } from "@dasa-health/alma-react"
import DoctorLink from '../../utils/getDoctorLink'
import arrayPagination from '../../utils/arrayPaginate'
import Pagination from './Pagination'
import Img from 'gatsby-image'
import { removeHTML } from '@cms/utils'
import getSlugByCountry from '../../utils/getSlugByCountry'

const ClinicalStaffWithPosts = (props) => {
  const doctor = props.doctor
  const posts = props.posts.edges
  const imageBackground = props.data.imagem_interna_corpo_clinico
  const linkDoctor = DoctorLink({ title: doctor.title_gender, name: doctor.name })
  const pageData = props.data
  const blogPageSlug = getSlugByCountry('blog')
  const MAX_CARDS_VISIBLE = 6
  const [postsList] = useState(arrayPagination(posts, MAX_CARDS_VISIBLE))
  const [countPages, setCountPages] = useState(postsList.length)
  const [page, setPage] = useState(0)
  const [firstRender, setFirstRender] = useState(true)

  useEffect(() => {
    if(firstRender) return setFirstRender(false)
    if(typeof window !== `undefined`) {
      window.scrollTo({
        top: 650,
        left: 100,
        behavior: 'smooth'
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])
  
  useEffect(() => {
    setCountPages(postsList.length)
  }, [postsList])

  return (
    <>
      <S.ContainerSingleClinicalStaff>
        <ContainerDasa>
          <S.ClinicalStaffContainer>
            <S.Image fluid={doctor.photo_medic.localFile.childImageSharp.fluid} alt={doctor.name} />
            
            <S.SpecialtyArea>{doctor.specialization_area}</S.SpecialtyArea>
            
            <S.Name>
              {doctor.title_gender} {doctor.name}
            </S.Name>

            <S.Council>
              <S.CouncilNumber>{doctor.council_number}</S.CouncilNumber>
            </S.Council>
        
            <S.ClinicalStaffDescription>
              <div dangerouslySetInnerHTML={{ __html: doctor.description }} />
            </S.ClinicalStaffDescription>

            {doctor.title_button && doctor.link_button && 
              <S.Button>
                <S.ButtonLink href={doctor.link_button}>
                  <Button
                    size="lg"
                    variant="secondary"
                  >
                    {doctor.title_button}
                  </Button>
                </S.ButtonLink>
              </S.Button>
            }
          </S.ClinicalStaffContainer>
        </ContainerDasa>

        <S.ImgMedical>
          {imageBackground
            ?
              <Img 
                fluid={imageBackground.localFile.childImageSharp.fluid}
                alt={imageBackground.alt_text}
              />
            :
              <ImgMedical />
          }
        </S.ImgMedical>
      </S.ContainerSingleClinicalStaff>

      <S.PublicationsContainer>
        <S.PublicationsContent>
          <S.PublicationsTitle>
            {pageData.titulo_publicacoes_corpo_clinico}
          </S.PublicationsTitle>

          <S.PublicationsList>
            {postsList[page].map((post) =>
              <S.PublicationCard key={post.node}>
                <S.PublicationCardTop>
                  <S.PublicationCategory
                    to={post.node.categories.length > 0 ? `/${blogPageSlug}/?category=${post.node.categories[0].name}` : ''}
                  >
                    {post.node.categories.length > 0 ? post.node.categories[0].name : ''}
                  </S.PublicationCategory>

                  <S.PublicationDate>
                    {post.node.date}
                  </S.PublicationDate>
                </S.PublicationCardTop>

                {post.node.featured_media !== null && post.node.featured_media.localFile
                ?
                  <>
                    <S.PubTitleLink to={`/${blogPageSlug}/${post.node.slug}/`}>
                      <S.PublicationTitle>
                        <S.PublicationImg 
                          fluid={post.node.featured_media.localFile.childImageSharp.fluid} 
                          alt={post.node.title}
                        />

                        <S.PubTitleWithImg>
                          {post.node.title.replace(/&#8211;/g, ' - ')}
                        </S.PubTitleWithImg>
                      </S.PublicationTitle>
                    </S.PubTitleLink>

                    <S.PublicationDescriptionWithImg to={`/${blogPageSlug}/${post.node.slug}/`}>
                      <p>{removeHTML(post.node.excerpt)}</p>
                    </S.PublicationDescriptionWithImg>
                  </>
                :
                  <>
                    <S.PubTitleLink to={`/${blogPageSlug}/${post.node.slug}/`}>
                      <S.PubTitle>
                          {post.node.title}
                      </S.PubTitle>
                    </S.PubTitleLink>

                    <S.PublicationDescription
                      to={`/${blogPageSlug}/${post.node.slug}`}
                    >
                      <p>{removeHTML(post.node.excerpt)}</p>
                    </S.PublicationDescription>
                  </>
                }

                <S.PublicationAuthor>
                  <S.PublicationAuthorImgLink
                    to={linkDoctor}
                  >
                    <S.PublicationAuthorImg
                      fluid={doctor.photo_medic.localFile.childImageSharp.fluid} 
                      alt={doctor.name}
                    />
                  </S.PublicationAuthorImgLink>

                  <S.PublicationAuthorNameLink 
                    to={linkDoctor}
                  >
                    <S.PublicationAuthorName>
                      {post.node.author.name}
                    </S.PublicationAuthorName>
                  </S.PublicationAuthorNameLink>
                </S.PublicationAuthor>
              </S.PublicationCard>
            )}
          </S.PublicationsList>
        </S.PublicationsContent>

        <Pagination 
          currentPage={page}
          totalPages={countPages}
          setPage={setPage}
          slug={linkDoctor}
        />
      </S.PublicationsContainer>

      <PinkLine />
    </>
  )
}

export default ClinicalStaffWithPosts
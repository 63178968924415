import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SingleClinicalStaff from '../components/single-clinical-staff'
import formatDoctorLink from '../utils/formatDoctorLink'

const clinicalStaff = ({ data, location, pageContext }) => {
  const doctor = data.doctor.acf
  const posts = data.posts
  const wordpressData = data.wordpressWpGatsbyPage.acf
  const doctorCanonical = `${wordpressData.canonical + formatDoctorLink(`${doctor.title_gender}-${data.doctor.slug}`)}/`

  return (
    <Layout
      location={location}
      pageContext={pageContext}
      crumbLabel={doctor.name}
    >
      <SEO 
        title={doctor.name}
        description={doctor.description}
        canonical={doctorCanonical}
        pathName={location.pathname}
      />
      <SingleClinicalStaff 
        doctor={doctor}
        posts={posts}
        data={wordpressData}
      />
    </Layout>
  )
}

clinicalStaff.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    id: PropTypes.string,
    usuario: PropTypes.number
  }),
  data: PropTypes.object.isRequired,
}

export default clinicalStaff

export const unitQuery = graphql`
  query($id: String, $usuario: Int, $slug: String!) {
    doctor: wordpressWpClinicalStaffPage(id: { eq: $id }) {
      acf {
        specialization_area
        name
        council_number
        description
        title_gender
        link_button
        title_button
        usuario
        photo_medic {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 441) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      slug
    }
    wordpressWpGatsbyPage(slug: { eq: $slug }) {
      acf {
        canonical
        titulo_publicacoes_corpo_clinico
        imagem_interna_corpo_clinico {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    posts: allWordpressPost(
      filter: { status: {eq: "publish"}, author: {wordpress_id: {eq: $usuario}} }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          slug
          date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
          author {
            name
            id
            url
            slug
            wordpress_id
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          excerpt
          categories {
            wordpress_id
            name
          }
        }
      }
    } 
  }
`
